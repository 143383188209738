// src/ToneButton.js

import React, { useState, useEffect, useRef } from 'react';

// Utility function to shuffle an array using the Fisher-Yates algorithm
const shuffleArray = (array) => {
    const arr = array.slice();
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
};

// List of audio files with their display names and file paths
const localTones = [
    { name: 'alarm-clock-short-6402', file: '/sounds/alarm-clock-short-6402.mp3' },
    { name: 'animals-buffalo-sound-232390', file: '/sounds/animals-buffalo-sound-232390.mp3' },
    { name: 'apex_legends_knockdown', file: '/sounds/apex_legends_knockdown.mp3' },
    { name: 'applause-01-253125', file: '/sounds/applause-01-253125.mp3' },
    { name: 'bumbum_granada', file: '/sounds/bumbum_granada.mp3' },
    { name: 'burp-37726', file: '/sounds/burp-37726.mp3' },
    { name: 'chicken_dance', file: '/sounds/chicken_dance.mp3' },
    { name: 'chicken_song', file: '/sounds/chicken_song.mp3' },
    { name: 'choking-2-244945', file: '/sounds/choking-2-244945.mp3' },
    { name: 'crash-down-some-stairs-106946', file: '/sounds/crash-down-some-stairs-106946.mp3' },
    { name: 'crowd-clapping-and-cheering-effect-272056', file: '/sounds/crowd-clapping-and-cheering-effect-272056.mp3' },
    { name: 'drunk_man', file: '/sounds/drunk_man.mp3' },
    { name: 'duck-quacking-37392', file: '/sounds/duck-quacking-37392.mp3' },
    { name: 'efek-suara-funny-putri-lucu-180275', file: '/sounds/efek-suara-funny-putri-lucu-180275.mp3' },
    { name: 'elephant-trumpets-growls-6047', file: '/sounds/elephant-trumpets-growls-6047.mp3' },
    { name: 'failed-295059', file: '/sounds/failed-295059.mp3' },
    { name: 'failure-1-89170', file: '/sounds/failure-1-89170.mp3' },
    { name: 'funny-meow-110120', file: '/sounds/funny-meow-110120.mp3' },
    { name: 'funny-secrect-laughing-baby-40554', file: '/sounds/funny-secrect-laughing-baby-40554.mp3' },
    { name: 'game-over-31-179699', file: '/sounds/game-over-31-179699.mp3' },
    { name: 'goofy-ahh-car-horn-200870', file: '/sounds/goofy-ahh-car-horn-200870.mp3' },
    { name: 'hahaha-251796', file: '/sounds/hahaha-251796.mp3' },
    { name: 'hankie-pankie-222974', file: '/sounds/hankie-pankie-222974.mp3' },
    { name: 'huh', file: '/sounds/huh.mp3' },
    { name: 'ireland-eas-alarm-264351', file: '/sounds/ireland-eas-alarm-264351.mp3' },
    { name: 'kazakhstan-eas-alarm-2022-loop-251843', file: '/sounds/kazakhstan-eas-alarm-2022-loop-251843.mp3' },
    { name: 'kl-peach-game-over-iii-142453', file: '/sounds/kl-peach-game-over-iii-142453.mp3' },
    { name: 'large-crash-with-cataiff-14490', file: '/sounds/large-crash-with-cataiff-14490.mp3' },
    { name: 'light-metal-crash-6765', file: '/sounds/light-metal-crash-6765.mp3' },
    { name: 'man-scream-121085', file: '/sounds/man-scream-121085.mp3' },
    { name: 'monkey_sound', file: '/sounds/monkey_sound.mp3' },
    { name: 'no-luck-too-bad-disappointing-sound-effect-112943', file: '/sounds/no-luck-too-bad-disappointing-sound-effect-112943.mp3' },
    { name: 'no-no-no-2-243751', file: '/sounds/no-no-no-2-243751.mp3' },
    { name: 'no-no-no-3-276680', file: '/sounds/no-no-no-3-276680.mp3' },
    { name: 'oh-no-the-car-exploded-10632', file: '/sounds/oh-no-the-car-exploded-10632.mp3' },
    { name: 'pinguin-220042', file: '/sounds/pinguin-220042.mp3' },
    { name: 'pipe-117724', file: '/sounds/pipe-117724.mp3' },
    { name: 'power-failure-44493', file: '/sounds/power-failure-44493.mp3' },
    { name: 'rimshot-joke-funny-80325', file: '/sounds/rimshot-joke-funny-80325.mp3' },
    { name: 'sinister-laugh-140131', file: '/sounds/sinister-laugh-140131.mp3' },
    { name: 'smashing-glass-102714', file: '/sounds/smashing-glass-102714.mp3' },
    { name: 'sooo-funny-82640', file: '/sounds/sooo-funny-82640.mp3' },
    { name: 'sound-effect-baby-laughing-01-237186', file: '/sounds/sound-effect-baby-laughing-01-237186.mp3' },
    { name: 'squeaky-ringer-101903', file: '/sounds/squeaky-ringer-101903.mp3' },
    { name: 'squeaky_squeaky_toy', file: '/sounds/squeaky_squeaky_toy.mp3' },
    { name: 'subhanallahi-wa-bihamdihi-with-reverb-301504', file: '/sounds/subhanallahi-wa-bihamdihi-with-reverb-301504.mp3' },
    { name: 'wah-wah-sad-trombone-6347', file: '/sounds/wah-wah-sad-trombone-6347.mp3' },
];

const ToneButton = () => {
    const [toneIndex, setToneIndex] = useState(0);
    const [tones, setTones] = useState([]);
    const [buttonColor, setButtonColor] = useState('#007bff'); // default color
    const [progress, setProgress] = useState(0); // progress percentage (0 to 100)
    const audioRef = useRef(null);

    // Array of possible button colors
    const colors = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#F933FF",
        "#FF33F9",
        "#33FFF9",
        "#F3FF33",
        "#FF8C00",
        "#00CED1",
        "#DA70D6"
    ];

    useEffect(() => {
        const shuffled = shuffleArray(localTones);
        setTones(shuffled);
        setToneIndex(0);

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    const playTone = () => {
        // Stop any currently playing audio
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }

        if (tones.length === 0) {
            console.warn('No tones available to play.');
            return;
        }

        let currentIndex = toneIndex;
        if (currentIndex >= tones.length) {
            const reshuffled = shuffleArray(tones);
            setTones(reshuffled);
            currentIndex = 0;
            setToneIndex(0);
        }

        const { file } = tones[currentIndex];
        const newAudio = new Audio(file);
        newAudio.play();
        audioRef.current = newAudio;
        setProgress(0); // Reset progress

        // Update progress as the audio plays
        newAudio.addEventListener('timeupdate', () => {
            if (newAudio.duration) {
                setProgress((newAudio.currentTime / newAudio.duration) * 100);
            }
        });
        newAudio.addEventListener('ended', () => {
            setProgress(0);
        });

        // Change the button color to a random color from the list
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        setButtonColor(randomColor);

        // Prepare index for next tone
        setToneIndex(currentIndex + 1);
    };

    // Circle configuration for the progress ring
    const radius = 100;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference * (1 - progress / 100);

    return (
        <div
            style={{
                position: 'relative',
                width: '220px',
                height: '220px',
                margin: 'auto',
            }}
        >
            {/* SVG progress ring */}
            <svg width="220" height="220" style={{ position: 'absolute', top: 0, left: 0 }}>
                {/* Background circle */}
                <circle
                    cx="110"
                    cy="110"
                    r={radius}
                    fill="none"
                    stroke="#eee"
                    strokeWidth="10"
                />
                {/* Progress circle */}
                <circle
                    cx="110"
                    cy="110"
                    r={radius}
                    fill="none"
                    stroke={buttonColor}
                    strokeWidth="10"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    transform="rotate(-90 110 110)"
                />
            </svg>
            {/* Circular button */}
            <button
                onClick={playTone}
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    width: '200px',
                    height: '200px',
                    borderRadius: '50%',
                    border: 'none',
                    backgroundColor: buttonColor,
                    color: '#fff',
                    fontSize: '20px',
                    cursor: 'pointer',
                    zIndex: 1,
                }}
            >
                Make noise.
            </button>
        </div>
    );
};

export default ToneButton;
